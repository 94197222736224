import ApiClient from '../net/ApiClient.js';
import Api from '../net/Api.js';

var DI = (function() {

	var services = {}

	let registerService = (name, service) => {
		services[name] = service
	}

	registerService('config', {
		oauth2: {
			clientId: 'cash_register_27341AD12WAsqwDf4',
			clientSecret: ''
		}
	})

	registerService('api', (() => {
		let client = new ApiClient(services.config.oauth2.clientId, services.config.oauth2.clientSecret)
		let api = new Api(services.config, client)
		return api
	})())


	return services
})()

export default DI