import DI from '../../../modules/di/DI.js';

const state = {
    products: [],
    categorizedList: [],
    product: null
}

const mutations = {
    async UPDATE_PRODUCTS (state, payload) {
        return new Promise(function(resolve) {
            // Sort them:
            state.categorizedList = payload
            payload.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
            state.products = payload
            resolve()
        })
    },
    async UPDATE_PRODUCT (state, payload) {
        return new Promise(function(resolve) {
            state.product = payload
            resolve()
        })
    }
}

const actions = {
    async getProducts ({ commit }, payload) {
        var response = await DI.api.getProducts(payload.settlementId)
        await commit('UPDATE_PRODUCTS', response.data)
    },
    async getProductById ({ commit }, payload) {
        var response = await DI.api.getProductById(payload.id)
        await commit('UPDATE_PRODUCT', response.data)
    }
}

const getters = {
    products: state => state.products,
    categorizedProducts: state => state.categorizedList,
    product: state => state.product
}

const productsModule = {
    state,
    mutations,
    actions,
    getters
}

export default productsModule;