<template>
    <div class="container">
        <!-- Breadcrumbs -->
        <!-- TODO GENERALIZE THEM -->
        <ul class="m-4 horizontal breadcrumbs">
            <li><router-link :to="{ name: 'dashboard', params: { id: profile.settlements[0].id }}"><span class="is-size-6">Dashboard</span></router-link></li>
            <li><router-link :to="{ name: 'revenue', params: { id: profile.settlements[0].id }}"><span class="is-size-6">Omzet</span></router-link></li>
            <li><router-link :to="{ name: 'revenue-day', params: { id: profile.settlements[0].id, date: date }}">{{ date }}</router-link></li>
        </ul>

        <div class="box shadow m-4">
            <h1 class="is-size-4 has-text-primary">Omzet {{ settlement ? 'van ' + settlement.name + ' op ' + date : '' }}</h1>
        </div>

        <div v-if="overview.length > 0" class="box shadow m-4 mt-5 overview is-hidden-mobile">
            <div class="has-text-primary overview-row ">Datum</div>
            <div class="has-text-primary overview-row has-text-right">Prijs</div>

            <template v-for="row in overview" :key="row">
                <div class="overview-row">
                    <router-link :to="{ name: 'order-details', params: { id: profile.settlements[0].id, orderId: row.id }}">{{ row.datetime }}</router-link>
                </div>
                <div class="overview-row has-text-right">€ {{ formatter.format(row.price, 2, ',', '.') }}</div>
            </template>
        </div>

        <div v-if="overview.length > 0" class="overview is-hidden-tablet">
            <template v-for="row in overview" :key="row">
                <div class="overview-row is-hidden-tablet">
                    <p class="ml-4 mr-4 mt-1 mb-1 is-italic has-text-left is-size-7">
                        <router-link :to="{ name: 'order-details', params: { id: profile.settlements[0].id, orderId: row.id }}">{{ row.datetime }}</router-link>
                    </p>
                    <div class="grid-1 ml-4 mr-4">
                        <div class="box underlined shadow overview-row has-text-right is-size-6">
                            <p class="has-text-primary">Prijs</p>
                            <p>€ {{ formatter.format(row.price, 2, ',', '.') }}</p>
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>
<script>

// import DI from '../../modules/di/DI.js'
import { mapGetters } from "vuex";
import Formatter from '../../plugins/Formatter.js';

export default {
    name: "RevenueDatePage",
    data () {
        return {
            formatter: Formatter,
            settlement: null,
            date: null,
            overview: [],
        }
    },
    computed: {
        ...mapGetters(["profile"]),
    },
    watch: {
        '$route.params.date' : function (date) {
            if (date) {
                this.load()
            }
        }
    },
    async created () {
        this.load()
    },
    methods: {
        async load() {
            this.date = this.getDate()
            this.settlement = this.getSettlement()
            if (this.settlement == null) {
                this.$router.push('/not-found')
            } else {
                await this.$store.dispatch(
                    'getDateRevenue', { 
                        settlementId: this.settlement.id,
                        date: this.date
                    }
                )
                this.overview = this.$store.state.revenueDate.revenue
            }
        },
        getSettlement () {
            var settlementId = this.$route.params.id
            var settlements = this.$store.state.profile.profile.settlements
            for(var i = 0; i < settlements.length; i++) {
                let settlement = settlements[i]
                if(settlement.id == settlementId) {
                    return settlement
                }
            }
            return null
        },
        getDate () {
            var d = this.$route.params.date
            return d
        }
    }
};
</script>

<style type="text/css" scoped>

    .overview {
        display: grid;
        grid-template-columns: auto auto;
    }

    .grid-1 {
        display: grid;
        grid-template-columns: 1fr;
        gap: 0 10px;
    }

    .overview-row {
        padding: 10px;
        text-align: left;
    }

    ul.horizontal {
        list-style-type: none;
        margin: 0;
        padding: 0;
        overflow: hidden;
    }
    ul.horizontal li {
        float: left;
        font-style: italic;
    }
    ul.breadcrumbs li:not(:first-child):before {
        content:"\A";
        width:5px;
        height:5px;
        border-radius:50%;
        display:inline-block;
        margin:3px 10px;
        background: var(--primary); /*var(--primary);*/
    }
    ul.breadcrumbs li:last-child:before {
        background: #999;
    }
    ul.breadcrumbs li > a.router-link-exact-active {
        color: #999 !important;
    }

    .grid-1 > .box {
        margin-bottom: 0;
    }


    @media only screen and (min-device-width : 0px) and (max-device-width : 480px) {
        .overview {
            display: grid;
            grid-template-columns: auto;
        }
    }

/*    .box.underlined {
        border-bottom: solid 2px var(--primary);
    }*/

</style>