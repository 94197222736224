<template>
    <div class="notification-container">
        <Transition name="fade" v-for="notification in notifications" :key="notification.id">
            <div class="notification-message box">
                <p>{{ notification.message }}</p>
            </div>
        </Transition>
    </div>
</template>

<script>
    import { mapGetters } from "vuex";

    export default {
        name: 'NotificationCentrum',
        methods: {
            close() {
                this.$emit('close');
            },
        },
        computed: {
            ...mapGetters(["notifications"]),
        },
        async created () {
            // Testing:
            // await this.$store.dispatch('addNotification', {
            //     message: 'An example notification'
            // });
            // setTimeout(async () => {
            //     await this.$store.dispatch('addNotification', {
            //         message: 'A second example notification with a bigger text in it',
            //     });
            // }, 1000);
            // setTimeout(async () => {
            //     await this.$store.dispatch('addNotification', {
            //         message: 'A third example notification with a bigger text in it, like lorem ipsum do mi so laksdjf asdkjhfa dfa djfhagdsfjhga sdafjh adhjgfa jhsgd ajdhsgf kjhagdskjf hgasdf asdgh fjahgd kjfhgak jdf'
            //     });
            // }, 1500);
            // setTimeout(async () => {
            //     await this.$store.dispatch('addNotification', {
            //         message: 'Fourth'
            //     });
            // }, 1800);
        }
    };

</script>

<style scoped>

    .notification-container {
        position: fixed;
        top: 15px;
        left: 15px;
        right: 15px;
        z-index: 999;
    }

    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.5s ease;
    }

    .fade-enter-from,
    .fade-leave-to {
        opacity: 0;
    }

    .box {
        color: #fefefe;
        background: #333;
    }

    @media screen and (min-width: 1024px) {
        .notification-container {
            display: flex;
            flex-direction: column-reverse;
            align-items: center;
            left: unset;
            top: 0;
            right: 0;
            bottom: 0;
            max-width: 300px;
        }

        .notification-message {
            min-height: 130px;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            position: relative;
            color: var(--dark-gray);
            background: #fff;
            margin-bottom: 20px;
            margin-right: 20px;
            font-size: 16px;
        }

        .notification-message p {
            text-align: center;
        }
    }


</style>