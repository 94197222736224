<template>
    <div class="container">
        <!-- Breadcrumbs -->
        <!-- TODO GENERALIZE THEM -->
        <ul class="m-4 horizontal breadcrumbs">
            <!-- <li><router-link to="/producten"><span class="is-size-6">Producten</span></router-link></li>
            <li><router-link to="/test"><span class="is-size-6">Test breadcrumb</span></router-link></li> -->
            <li><router-link :to="{ name:'dashboard', params: { id: settlement ? settlement.id : '0' }}"><span class="is-size-6">Dashboard</span></router-link></li>
            <!-- TODO USE query param and :to {name:'Dashboard', params: {id: this.settlement.id }} -->
            <!-- ABOVE LINK DOES NOT WORK!!! -->
        </ul>

        <div class="box shadow m-4">
            <h1 class="is-size-4 has-text-primary">Dashboard {{ settlement ? 'van ' + settlement.name : '' }}</h1>
            <p class="is-size-7">De afgelopen 30 dagen</p>
        </div>

        <!-- Statistics -->
        <div class="columns m-1">
            <div class="column">
                <div class="box underlined">
                    <h2 class="is-size-5 has-text-primary">Omzet</h2>
                    <h3 class="is-size-6">€ {{ revenue }}</h3>
                </div>
            </div>
            <div class="column">
                <div class="box underlined">
                    <h2 class="is-size-5 has-text-primary">Bestellingen</h2>
                    <h3 class="is-size-6">{{ orderCount }}</h3>
                </div>
            </div>
            <div class="column">
                <div class="box underlined">
                    <h2 class="is-size-5 has-text-primary">Meest verkocht</h2>
                    <h3 class="is-size-6">{{ mostSoldProduct }}</h3>
                </div>
            </div>
        </div>

        <!-- Revenue chart -->
        <div>
            <apexchart id="vuechart-example" :type="type" :options="options" :series="series"></apexchart>
        </div>
    </div>
</template>
<script>

// import DI from '../../modules/di/DI.js'
import { mapGetters } from "vuex";
import VueApexCharts from "vue3-apexcharts";
import Formatter from '../../plugins/Formatter.js';

export default {
    components: {
        apexchart: VueApexCharts
    },
    name: "DashboardPage",
    data: function() {
        return {
            settlement: null,
            revenue: '0,00',
            orderCount: 0,
            mostSoldProduct: 'Onbekend',
            type: 'area',
            options: {
                chart: {
                    id: 'revenue-chart',
                    stacked: true,
                    zoom: {
                        enabled: false
                    }
                },
                colors: [
                    '#' + this.$store.state.profile.profile.settlements[0].color
                ],
                stroke: {
                    curve: 'smooth'
                },
                yaxis: {
                    show: true,
                    labels: {
                        formatter: (value) => {
                            return '€ ' + Formatter.format(value, 2, ',', '.');
                        }
                    }
                },
                xaxis: {
                    type: 'datetime'
                    // ,
                    // categories: []
                }
            },
            series: [
                {
                    name: 'Omzet - totaal',
                    data: []
                }
            ],
        }
    },
    computed: {
        ...mapGetters(["profile", "statistics"]),
    },
    watch: {
        '$route.params.id' : function (id) {
            if (id) {
                this.load()
            }
        }
    },
    async created () {
        this.load()
    },
    methods: {
        async load() {
            this.settlement = this.getSettlement()
            if (this.settlement == null) {
                this.$router.push('/not-found')
            } else {
                await this.$store.dispatch('getStatistics', { settlementId: this.settlement.id })
                this.revenue = Formatter.format(this.$store.state.dashboard.statistics.revenue, 2, '.', ',')
                this.orderCount = this.$store.state.dashboard.statistics.ordersCount
                this.mostSoldProduct = this.$store.state.dashboard.statistics.mostSoldProduct

                var categories = this.$store.state.dashboard.statistics.orderDays.map(x => x.day);
                var isoDates = categories.map(date => {
                    var day = date.substring(0, 2);
                    var month = date.substring(3, 5);
                    var year = date.substring(6);
                    return new Date(year +  '-' + month + '-' + day).getTime();
                })
                this.options.xaxis.min = isoDates[0];
                var data = this.$store.state.dashboard.statistics.orderDays.map(x => x.amount)

                var graphData = [];
                for( var i = 0; i < isoDates.length; i++) {
                    var y = data[i];
                    var x = isoDates[i];
                    graphData.push({ x: x, y: y});
                }

                this.series[0].data = graphData
                this.options.colors = [
                    '#' + this.settlement.color
                ]
            }
        },
        getSettlement () {
            var settlementId = this.$route.params.id
            var settlements = this.$store.state.profile.profile.settlements
            for(var i = 0; i < settlements.length; i++) {
                let settlement = settlements[i]
                if(settlement.id == settlementId) {
                    return settlement
                }
            }
            return null
        }
    }
};
</script>

<style type="text/css" scoped>

    ul.horizontal {
        list-style-type: none;
        margin: 0;
        padding: 0;
        overflow: hidden;
    }
    ul.horizontal li {
        float: left;
        font-style: italic;
    }
    ul.breadcrumbs li:not(:first-child):before {
        content:"\A";
        width:5px;
        height:5px;
        border-radius:50%;
        display:inline-block;
        margin:3px 10px;
        background: var(--primary); /*var(--primary);*/
    }
    ul.breadcrumbs li:last-child:before {
        background: #999;
    }
    ul.breadcrumbs li > a.router-link-exact-active {
        color: #999 !important;
    }


   /* @media only screen and (min-device-width : 0px) and (max-device-width : 480px) {
        #card-container {
            width: 80%;
        }
    }*/

</style>