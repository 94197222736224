const state = {
    notifications: {},
    counter: 0
}

const mutations = {
    async ADD_NOTIFICATION (state, payload) {
        return new Promise(function(resolve) {
            payload['id'] = state.counter++;
            payload['duration'] = payload.duration != null 
                ? payload.duration 
                : Math.max(Math.min(payload.message.length * 100, 1500), 4000) // minimal of 1,5 seconds. Max of 4 seconds
            state.notifications[payload.id] = payload
            
            setTimeout(() => {
                delete state.notifications[payload.id]
            }, payload.duration)

            resolve()
        })
    }
}

const actions = {
    async addNotification ({ commit }, payload) {
        await commit('ADD_NOTIFICATION', payload)
    }
}

const getters = {
    notifications: state => state.notifications
}

const notificationsModule = {
    state,
    mutations,
    actions,
    getters
}

export default notificationsModule;