<template>
    <div class="container">
        <h1 class="is-size-4 mt-4">Oops, er ging iets verkeerd</h1>
        <h2 class="is-size-6">Er is iets misgegaan, probeer het opnieuw.</h2>
        <router-link to="/">Home</router-link>
    </div>
</template>
<script>

export default {
    name: "NotFoundPage",
};
</script>

<style type="text/css" scoped>


</style>