import DI from '../../../modules/di/DI.js';

const state = {
	profile: null
}

const mutations = {
	// Mutate the products that we offer:
	async UPDATE_PROFILE (state, payload) {
		
		return new Promise(function(resolve) {
			// TODO: ColorUtiliry for this !!!
			// Can I override the primary color?????
			// Get the root element
			var r = document.querySelector(':root');
			// Set it:
			r.style.setProperty('--primary', '#' +payload.settlements[0].color);
			state.profile = payload
			resolve()
		})
	}
}

const actions = {
	// Get the profile from the API:
	async getProfile ({ commit }) {
		var response = await DI.api.getProfile()
		await commit('UPDATE_PROFILE', response.data)
	}
}

const getters = {
	profile: state => state.profile
}

const profileModule = {
	state,
	mutations,
	actions,
	getters
}

export default profileModule;