import DI from '../../../modules/di/DI.js';

const state = {
	order: null
}

const mutations = {
	async UPDATE_ORDER (state, payload) {
		return new Promise(function(resolve) {
			state.order = payload
			resolve()
		})
	}
}

const actions = {
	async getOrder ({ commit }, payload) {
		var response = await DI.api.getOrder(payload.orderId)
		await commit('UPDATE_ORDER', response.data)
	}
}

const getters = {
	order: state => state.order
}

const orderModule = {
	state,
	mutations,
	actions,
	getters
}

export default orderModule;