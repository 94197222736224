import { createRouter, createWebHashHistory } from 'vue-router'

import Login from '../components/home/Login.vue'
import DashboardPage from '../components/dashboard/DashboardPage.vue'
import RevenuePage from '../components/revenue/RevenuePage.vue'
import RevenueDatePage from '../components/revenue/RevenueDatePage.vue'
import NotFoundPage from '../components/home/NotFoundPage.vue'
import ProductsPage from '../components/product/ProductsPage.vue'
import ProductDetailsPage from '../components/product/ProductDetailsPage.vue'
import ProductCreatePage from '../components/product/ProductCreatePage.vue'
import OrderDetailsPage from '../components/order/OrderDetailsPage.vue'

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/not-found',
    name: 'not-found',
    component: NotFoundPage,
    meta: {
      title: 'Uw Digitale Kassa | Niet gevonden',
      metaTags: [
        {
          name: 'description',
          content: ''
        },
        {
          property: 'og:description',
          content: ''
        }
      ]
    }
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      title: 'Uw Digitale Kassa | Login',
      metaTags: [
        {
          name: 'description',
          content: 'Uw Digitale Kassa beheren via het online portaal. Log in om te starten met het productenbeheer.'
        },
        {
          property: 'og:description',
          content: 'Uw Digitale Kassa beheren via het online portaal. Log in om te starten met het productenbeheer.'
        }
      ]
    }
  },
  {
    path: '/dashboard/:id',
    name: 'dashboard',
    component: DashboardPage,
    meta: {
      title: 'Uw Digitale Kassa | Dashboard',
      metaTags: [
        {
          name: 'description',
          content: 'Het dashboard van Uw Digitale Kassa. Bekijk de statistieken van Uw Digitale Kassa.'
        },
        {
          property: 'og:description',
          content: 'Het dashboard van Uw Digitale Kassa. Bekijk de statistieken van Uw Digitale Kassa.'
        }
      ]
    }
  },
  {
    path: '/revenue/:id',
    name: 'revenue',
    component: RevenuePage,
    meta: {
      title: 'Uw Digitale Kassa | Omzet',
      metaTags: [
        {
          name: 'description',
          content: 'Bekijk de omzet van Uw Digitale Kassa.'
        },
        {
          property: 'og:description',
          content: 'Bekijk de omzet van Uw Digitale Kassa.'
        }
      ]
    }
  },
  {
    path: '/revenue/:id/date/:date',
    name: 'revenue-day',
    component: RevenueDatePage,
    meta: {
      title: 'Uw Digitale Kassa | Dagomzet',
      metaTags: [
        {
          name: 'description',
          content: 'Bekijk de dagomzet van Uw Digitale Kassa.'
        },
        {
          property: 'og:description',
          content: 'Bekijk de dagomzet van Uw Digitale Kassa.'
        }
      ]
    }
  },
  {
    path: '/settlement/:id/orders/:orderId',
    name: 'order-details',
    component: OrderDetailsPage,
    meta: {
      title: 'Uw Digitale Kassa | Bestelling details',
      metaTags: [
        {
          name: 'description',
          content: 'Bekijk de details van uw bestelling van Uw Digitale Kassa.'
        },
        {
          property: 'og:description',
          content: 'Bekijk de details van uw bestelling van Uw Digitale Kassa.'
        }
      ]
    }
  },
  {
    path: '/settlement/:settlementId/products',
    name: 'products',
    component: ProductsPage,
    meta: {
      title: 'Uw Digitale Kassa | Producten',
      metaTags: [
        {
          name: 'description',
          content: 'Beheer de producten van Uw Digitale Kassa.'
        },
        {
          property: 'og:description',
          content: 'Beheer de producten van Uw Digitale Kassa.'
        }
      ]
    }
  },
  {
    path: '/settlement/:settlementId/products/create',
    name: 'create-product',
    component: ProductCreatePage,
    meta: {
      title: 'Uw Digitale Kassa | Product | Details',
      metaTags: [
        {
          name: 'description',
          content: 'Beheer de details van uw product.'
        },
        {
          property: 'og:description',
          content: 'Beheer de details van uw product.'
        }
      ]
    }
  },
  {
    path: '/settlement/:settlementId/product-details/:productId',
    name: 'product-details',
    component: ProductDetailsPage,
    meta: {
      title: 'Uw Digitale Kassa | Product | Details',
      metaTags: [
        {
          name: 'description',
          content: 'Beheer de details van uw product.'
        },
        {
          property: 'og:description',
          content: 'Beheer de details van uw product.'
        }
      ]
    }
  },
  // {
  //   path: '/products',
  //   //redirect: '/inventory'
  //   component: ProductPage,
  //   meta: {
  //     title: 'Zneckbar | Overzicht',
  //     metaTags: [
  //       {
  //         name: 'description',
  //         content: 'Bestel een heerlijke maaltijd bij de Zneckbar'
  //       },
  //       {
  //         property: 'og:description',
  //         content: 'Bestel een heerlijke maaltijd bij de Zneckbar'
  //       }
  //     ]
  //   }
  // },
  // {
  //   path: '/order-confirmation',
  //   //redirect: '/inventory'
  //   component: OrderConfirmationPage,
  //   meta: {
  //     title: 'Zneckbar | Bevestiging',
  //     metaTags: [
  //       {
  //         name: 'description',
  //         content: 'De bevestiging van uw bestelling'
  //       },
  //       {
  //         property: 'og:description',
  //         content: 'De bevestiging van uw bestelling'
  //       }
  //     ]
  //   }
  // },
]
const router = createRouter({
  history: createWebHashHistory(),
  routes
})

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if(nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if(previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if(!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    // We use this to track which meta tags we create so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
  // Add the meta tags to the document head.
  .forEach(tag => document.head.appendChild(tag));

  next();
});


export default router