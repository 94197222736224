<template>
    <div class="container">
        <!-- Breadcrumbs -->
        <!-- TODO GENERALIZE THEM -->
        <ul class="m-4 horizontal breadcrumbs">
            <li><router-link :to="{ name: 'dashboard', params: { id: profile.settlements[0].id }}"><span class="is-size-6">Dashboard</span></router-link></li>
            <li><router-link :to="{ name: 'revenue', params: { id: profile.settlements[0].id }}"><span class="is-size-6">Omzet</span></router-link></li>
        </ul>

        <div class="box shadow m-4">
            <h1 class="is-size-4 has-text-primary">Bestelling #{{ orderId }}</h1>
        </div>

        <div class="box receipt shadow m-4 " v-if="order != null">
            <h3 class="is-size-4 m-3 has-text-centered">{{ profile.name }}</h3>
            <p v-if="settlement != null" class="has-text-centered">{{ settlement.address }}</p>
            <p v-if="settlement != null" class="has-text-centered">{{ settlement.addressTwo }}</p>
            <p v-if="settlement != null && settlement.website != null" class="has-text-centered">{{ settlement.website }}</p>
            <p class="has-text-centered">{{ order.orderedAt }} uur</p>

            <p class="has-text-centered m-3 has-text-weight-bold">Bedankt voor uw bestelling!</p>


            <!-- ...... -->
            
            <div class="price-grid">

                <template v-for="product in order.products" :key="product.id">
                    <div>
                        <p>{{product.quantity}}&nbsp;{{ product.name }}</p>
                        <template v-for="addition in product.additions" :key="addition.id">
                            <p>&nbsp;&nbsp;&nbsp;&nbsp;+ {{ addition.name }}</p>
                        </template>
                    </div>
                    <p class="has-text-right">{{ formatter.format(product.orderPrice, 2, ',', '.') }}</p>


                </template>

                <p class="is-size-4 has-text-weight-bold mt-3 mb-3">Totaal</p>
                <p class="is-size-4 has-text-weight-bold mt-3 mb-3 has-text-right">{{ formatter.format(order.price, 2, ',', '.') }}</p>


            </div>
            <!-- Total               6,65   -->

            <!-- BTW groep           BTW -->
            <!-- Btw Laag 9%         0,53 -->
            <!-- Btw Hoog 21%        0,03 -->
        </div>
    </div>
</template>
<script>

// import DI from '../../modules/di/DI.js'
import { mapGetters } from "vuex";
import Formatter from '../../plugins/Formatter.js';

export default {
    name: "OrderDetailsPage",
    data () {
        return {
            formatter: Formatter,
            settlement: null,
            orderId: null,
            order: null
        }
    },
    computed: {
        ...mapGetters(["profile"]),
    },
    watch: {
        '$route.params.id' : function (id) {
            if (id) {
                this.load()
            }
        },
        '$route.params.orderId' : function (orderId) {
            if (orderId) {
                this.load()
            }
        }
    },
    async created () {
        this.load()
    },
    methods: {
        async load() {
            this.orderId =  this.getOrderId()
            this.settlement = this.getSettlement()
            if (this.settlement == null) {
                this.$router.push('/not-found')
            } else {
                await this.$store.dispatch(
                    'getOrder', { 
                        orderId: this.orderId
                    }
                )
                this.order = this.$store.state.order.order
            }
        },
        getSettlement () {
            var settlementId = this.$route.params.id
            var settlements = this.$store.state.profile.profile.settlements
            for(var i = 0; i < settlements.length; i++) {
                let settlement = settlements[i]
                if(settlement.id == settlementId) {
                    return settlement
                }
            }
            return null
        },
        getOrderId () {
            var id = this.$route.params.orderId
            return id
        }
    }
};
</script>

<style type="text/css" scoped>

    .price-grid {
        display: grid;
        grid-template-columns: 1fr auto;
        gap: 0 10px;
    }

    .overview {
        display: grid;
        grid-template-columns: auto auto auto auto;
    }

    .grid-3 {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 0 10px;
    }

    .overview-row {
        padding: 10px;
        text-align: left;
    }

    ul.horizontal {
        list-style-type: none;
        margin: 0;
        padding: 0;
        overflow: hidden;
    }
    ul.horizontal li {
        float: left;
        font-style: italic;
    }
    ul.breadcrumbs li:not(:first-child):before {
        content:"\A";
        width:5px;
        height:5px;
        border-radius:50%;
        display:inline-block;
        margin:3px 10px;
        background: var(--primary); /*var(--primary);*/
    }
    ul.breadcrumbs li:last-child:before {
        background: #999;
    }
    ul.breadcrumbs li > a.router-link-exact-active {
        color: #999 !important;
    }

    .grid-3 > .box {
        margin-bottom: 0;
    }

    .receipt {
        max-width: 400px;
        margin: 0 auto;
    }

    @media only screen and (min-device-width : 0px) and (max-device-width : 480px) {
        .overview {
            display: grid;
            grid-template-columns: auto;
        }
    }

/*    .box.underlined {
        border-bottom: solid 2px var(--primary);
    }*/

</style>