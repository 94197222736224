import axios from 'axios';
import Auth from '../auth/Auth.js';


var apiClient = function(clientId, clientSecret) {
    let client = axios.create();

    let refreshAccessToken = async function() {
        return new Promise((resolve, reject) => {
            client.post('/api/auth/login', {
                grant_type: 'refresh_token',
                refresh_token: Auth.getRefreshToken(),
                client_id: clientId,
                client_secret: clientSecret
            }).then((response) => {
                Auth.setAccessToken(response.data.access_token)
                Auth.setRefreshToken(response.data.refresh_token)
                resolve(response.data.access_token)
            }).catch((e) => {
                if (e.response.data.error) {
                    console.log(e.response.data.error)
                    reject(e.response.data.error)
                }
                reject('something_went_wrong')
            });
        })
    }

    // Attach bearer token when possible
    client.interceptors.request.use (
        async config => {
            var accessToken = Auth.getAccessToken()
            if (accessToken) {
                // console.log('Set access token in the header')
                config.headers = {
                    'Authorization': 'Bearer ' + accessToken
                }
            }
            config.url = process.env.VUE_APP_API_URL + config.url
            return config;
        },
        error => {
            Promise.reject(error)
        }
    )

    // Interceptor for API requests to check for 401, to refresh if needed:
    client.interceptors.response.use(
        (response) => {
            // Return success responses
            return response
        }, 
        // Catch errors:
        async function (error) {
            const originalRequest = error.config
            // Make sure the request has not been retried yet:
            if (error.response.status === 401 && !originalRequest._retry) {
                originalRequest._retry = true;
                // Refresh the access token
                const accessToken = await refreshAccessToken();

                axios.defaults.headers.common['Authorization'] = 'Bearer ' + accessToken;
                // This returned client HAS the config.url overwritten again with the process environment, resolve that:
                originalRequest.url = originalRequest.url.substring(process.env.VUE_APP_API_URL);

                return client(originalRequest);
            } else if (error.response.status === 401) {
                Auth.logout();
                return Promise.reject(error)
            }
            return Promise.reject(error);
        }
    )
    
    // Expose this client
    return client
}

export default apiClient