
var translationModule = (function() {

    let MAP = {
        nl: {
            invalid_credentials: "Incorrecte gegevens. De gebruikersnaam of het wachtwoord is onjuist.",
            invalid_request: 'Vul zowel een gebruiker als een wachtwoord in.'
        }
    };

    let currentLang = 'nl'

    return {
        t: (key) => {
            return MAP[currentLang][key] ?? key
        }
    }
})()

export default translationModule