import DI from '../../../modules/di/DI.js';

const state = {
	revenue: null,
	total: {
		pin: 0,
		cash: 0,
		tax: {
			low: 0,
			high: 0
		}
	}
}

const mutations = {
	async UPDATE_REVENUE (state, payload) {
		return new Promise(function(resolve) {
			state.revenue = payload

			// Reset totals
			state.total.pin = 0;
			state.total.cash = 0;
			state.total.tax.low = 0;
			state.total.tax.high = 0;

			for( var i = 0; i < payload.length; i++) {
				var current = payload[i]

				state.total.pin += current.pin
				state.total.cash += current.cash
				state.total.tax.low += current.tax.low
				state.total.tax.high += current.tax.high 
			}

			resolve()
		})
	}
}

const actions = {
	// Get the profile from the API:
	async getRevenue ({ commit }, payload) {
		var response = await DI.api.getRevenue(payload.settlementId, payload.startDate, payload.endDate)
		await commit('UPDATE_REVENUE', response.data)
	}
}

const getters = {
	revenue: state => state.revenue
}

const revenueModule = {
	state,
	mutations,
	actions,
	getters
}

export default revenueModule;