var auth = (function() {

	var accessToken = null;
	var refreshToken = null

	let load = () => {
		accessToken = window.localStorage.getItem('ACCESS_TOKEN')
		refreshToken = window.localStorage.getItem('REFRESH_TOKEN')
	}

	let setAccessToken = (token) => {
		accessToken = token
		if (token === null) {
			window.localStorage.removeItem('ACCESS_TOKEN')	
			return
		}
		window.localStorage.setItem('ACCESS_TOKEN', token)
	}
	
	let setRefreshToken = (token) => {
		refreshToken = token
		if (token === null) {
			window.localStorage.removeItem('REFRESH_TOKEN')	
			return
		}
		window.localStorage.setItem('REFRESH_TOKEN', token)
	}

	// Load:
	load()

	return {
		setAccessToken: setAccessToken,
		setRefreshToken: setRefreshToken,
		getAccessToken: () => {
			return accessToken
		},
		getRefreshToken: () => {
			return refreshToken
		},
		hasLoggedIn: () => {
			return accessToken != null
		},
		logout: () => {
			setAccessToken(null)
			setRefreshToken(null)

			var r = document.querySelector(':root');
			// Set it:
			r.style.setProperty('--primary', '#edbe15');
		}
	}


})()

export default auth