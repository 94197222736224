<template>
    <div class="container">
        <!-- Breadcrumbs -->
        <!-- TODO GENERALIZE THEM -->
        <ul class="m-4 horizontal breadcrumbs">
            <li><router-link :to="{ name: 'dashboard', params: { id: profile.settlements[0].id }}"><span class="is-size-6">Dashboard</span></router-link></li>
            <li><router-link :to="{ name: 'revenue', params: { id: profile.settlements[0].id }}"><span class="is-size-6">Omzet</span></router-link></li>
        </ul>

        <div class="box shadow m-4">
            <h1 class="is-size-4 has-text-primary">Omzet {{ settlement ? 'van ' + settlement.name : '' }}</h1>
            <form class="mt-4" @submit="search">
                <div class="columns">
                    <div class="column">
                        <input v-model="startDate" type="date" name="startDate">
                    </div>
                    <div class="column">
                        <input v-model="endDate" type="date" name="endDate">
                    </div>
                    <div class="column">
                        <input class="button is-primary" type="submit" name="" value="Zoeken">
                    </div>
                </div>
            </form>
        </div>

        <div v-if="overview.length > 0" class="box shadow m-4 mt-5 is-hidden-mobile">
            <h1 class="is-size-4 has-text-primary">Totalen (in geselecteerde periode)</h1>
            <div class="total">
                <div class="has-text-primary total-row has-text-right">Pin</div>
                <div class="has-text-primary total-row has-text-right">Cash</div>
                <div class="has-text-primary total-row has-text-right">Totaal (incl. BTW)</div>
                <div class="has-text-primary total-row has-text-right">BTW</div>

                <div class="overview-row has-text-right">€ {{ formatter.format(total.pin, 2, ',', '.') }}</div>
                <div class="overview-row has-text-right">€ {{ formatter.format(total.cash, 2, ',', '.') }}</div>
                <div class="overview-row has-text-right">€ {{ formatter.format(total.pin + total.cash, 2, ',', '.') }}</div>
                <div class="overview-row has-text-right">
                    <p>BTW Laag 9% € {{ formatter.format(total.tax.low, 2, ',', '.') }}</p>
                    <p>BTW Hoog 21% € {{ formatter.format(total.tax.high, 2, ',', '.') }}</p>
                </div>
            </div>
        </div>

        <div v-if="overview.length > 0" class="box shadow m-4 mt-5 overview is-hidden-mobile">
            <div class="has-text-primary overview-row ">Datum</div>
            <div class="has-text-primary overview-row has-text-right">Pin</div>
            <div class="has-text-primary overview-row has-text-right">Cash</div>
            <div class="has-text-primary overview-row has-text-right">Totaal (incl. BTW)</div>
            <div class="has-text-primary overview-row has-text-right">BTW</div>
            <template v-for="row in overview" :key="row">
                <div class="overview-row"><router-link :to="{name: 'revenue-day', params: { id: profile.settlements[0].id, date: row.day}}">{{ row.day }}</router-link></div>
                <div class="overview-row has-text-right">€ {{ formatter.format(row.pin, 2, ',', '.') }}</div>
                <div class="overview-row has-text-right">€ {{ formatter.format(row.cash, 2, ',', '.') }}</div>
                <div class="overview-row has-text-right">€ {{ formatter.format(row.pin + row.cash, 2, ',', '.') }}</div>
                <div class="overview-row has-text-right">
                    <p>BTW Laag 9% € {{ formatter.format(row.tax.low, 2, ',', '.') }}</p>
                    <p>BTW Hoog 21% € {{ formatter.format(row.tax.high, 2, ',', '.') }}</p>
                </div>
            </template>
        </div>

        <div v-if="overview.length > 0" class="overview is-hidden-tablet">
            <template v-for="row in overview" :key="row">
                <div class="overview-row is-hidden-tablet">
                    <p class="ml-4 mr-4 mt-1 mb-1 is-italic has-text-left is-size-7"><router-link :to="{name: 'revenue-day', params: { id: profile.settlements[0].id, date: row.day}}">{{ row.day }}</router-link></p>
                    <div class="grid-3 ml-4 mr-4">
                        <div class="box underlined shadow overview-row has-text-right is-size-6">
                            <p class="has-text-primary">Pin</p>
                            <p>€ {{ formatter.format(row.pin, 2, ',', '.') }}</p>
                        </div>

                        <div class="box underlined shadow overview-row has-text-right is-size-6">
                            <p class="has-text-primary">Cash</p>
                            <p>€ {{ formatter.format(row.cash, 2, ',', '.') }}</p>
                        </div>
                        
                        <div class="box underlined shadow overview-row has-text-right is-size-6">
                            <p class="has-text-primary">Totaal</p>
                            <p>€ {{ formatter.format(row.pin + row.cash, 2, ',', '.') }}</p>
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>
<script>

// import DI from '../../modules/di/DI.js'
import { mapGetters } from "vuex";
import Formatter from '../../plugins/Formatter.js';

export default {
    name: "RevenuePage",
    data () {
        return {
            formatter: Formatter,
            settlement: null,
            overview: [],
            total: null,
            startDate: this.getStartDate(),
            endDate: this.getEndDate()
        }
    },
    computed: {
        ...mapGetters(["profile"]),
    },
    watch: {
        '$route.params.id' : function (id) {
            if (id) {
                this.load()
            }
        }
    },
    async created () {
        this.load()
    },
    methods: {
        search(e) {
            e.preventDefault();
            this.load()
        },
        getStartDate() {
            var days = 7;
            var date = new Date();
            var res = date.setTime(date.getTime() - (days * 24 * 60 * 60 * 1000));
            date = new Date(res)
            return date.getFullYear() + '-' + (date.getMonth() + 1).toString().padStart(2, '0') + '-' + date.getDate().toString().padStart(2, '0');
        },
        getEndDate() {
            let today = new Date();
            return today.getFullYear() + '-' + (today.getMonth() + 1).toString().padStart(2, '0') + '-' + today.getDate().toString().padStart(2, '0');
        },
        async load() {
            this.settlement = this.getSettlement()
            if (this.settlement == null) {
                this.$router.push('/not-found')
            } else {
                await this.$store.dispatch(
                    'getRevenue', { 
                        settlementId: this.settlement.id,
                        startDate: this.startDate,
                        endDate: this.endDate
                    }
                )
                this.overview = this.$store.state.revenue.revenue
                this.total = this.$store.state.revenue.total
            }
        },
        getSettlement () {
            var settlementId = this.$route.params.id
            var settlements = this.$store.state.profile.profile.settlements
            for(var i = 0; i < settlements.length; i++) {
                let settlement = settlements[i]
                if(settlement.id == settlementId) {
                    return settlement
                }
            }
            return null
        }
    }
};
</script>

<style type="text/css" scoped>

    .total {
        display: grid;
        grid-template-columns: auto auto auto auto;
    }

    .overview {
        display: grid;
        grid-template-columns: auto auto auto auto auto;
    }

    .grid-3 {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 0 10px;
    }

    .overview-row,
    .total-row {
        padding: 10px;
        text-align: left;
    }

    ul.horizontal {
        list-style-type: none;
        margin: 0;
        padding: 0;
        overflow: hidden;
    }
    ul.horizontal li {
        float: left;
        font-style: italic;
    }
    ul.breadcrumbs li:not(:first-child):before {
        content:"\A";
        width:5px;
        height:5px;
        border-radius:50%;
        display:inline-block;
        margin:3px 10px;
        background: var(--primary); /*var(--primary);*/
    }
    ul.breadcrumbs li:last-child:before {
        background: #999;
    }
    ul.breadcrumbs li > a.router-link-exact-active {
        color: #999 !important;
    }

    .grid-3 > .box {
        margin-bottom: 0;
    }


    @media only screen and (min-device-width : 0px) and (max-device-width : 480px) {
        .overview {
            display: grid;
            grid-template-columns: auto;
        }
    }

/*    .box.underlined {
        border-bottom: solid 2px var(--primary);
    }*/

</style>