import DI from '../../../modules/di/DI.js';

const state = {
	statistics: null
}

const mutations = {
	// Mutate the products that we offer:
	async UPDATE_STATISTICS (state, payload) {
		
		return new Promise(function(resolve) {
			state.statistics = payload
			resolve()
		})
	}
}

const actions = {
	// Get the profile from the API:
	async getStatistics ({ commit }, payload) {
		var response = await DI.api.getStatistics(payload.settlementId)
		await commit('UPDATE_STATISTICS', response.data)
	}
}

const getters = {
	statistics: state => state.statistics
}

const dashboardModule = {
	state,
	mutations,
	actions,
	getters
}

export default dashboardModule;