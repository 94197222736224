import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import contactInfo from './contact-info.js'
import VueApexCharts from "vue3-apexcharts";

import './../node_modules/@fortawesome/fontawesome-free/js/solid.js'
import './../node_modules/@fortawesome/fontawesome-free/js/fontawesome.js'

require("./assets/main.scss")

// Define the app:
const app = createApp(App)
// Configure the contact info:
// app.config.globalProperties.contactInfo = contactInfo
// Initialize the app
app
	.use(store)
	.use(router)
	.use(VueApexCharts)
	.mount('#app')
