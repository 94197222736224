import { createStore } from 'vuex'
import product from './modules/product'
import profile from './modules/profile'
import dashboard from './modules/dashboard'
import revenue from './modules/revenue'
import notifications from './modules/notifications'
import revenueDate from './modules/revenue-date'
import order from './modules/order'

export default createStore({
	modules: {
		profile,
		product,
		dashboard,
		revenue,
		notifications,
		revenueDate,
		order
	}
})