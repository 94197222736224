import DI from '../../../modules/di/DI.js';

const state = {
	revenue: null
}

const mutations = {
	async UPDATE_DATE_REVENUE (state, payload) {
		return new Promise(function(resolve) {
			state.revenue = payload
			resolve()
		})
	}
}

const actions = {
	async getDateRevenue ({ commit }, payload) {
		var response = await DI.api.getDateRevenue(payload.settlementId, payload.date)
		await commit('UPDATE_DATE_REVENUE', response.data)
	}
}

const getters = {
	revenueDate: state => state.revenue
}

const revenueModule = {
	state,
	mutations,
	actions,
	getters
}

export default revenueModule;